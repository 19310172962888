<template>
    <div>
        <strong>ECO File Upload</strong>
        <br>
        <div class="form-group">
            <label>ECO # </label>
            <input class="form-control" type="text" v-model="state.referenceID" />
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";

    export default {
        name: "ECO",
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize();
        },
        mounted() {
            fileUploadStore.blankReferenceID();
        }
    }
</script>

<style scoped>

</style>